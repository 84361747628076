<template lang="pug">
    .person-description
        //loading(v-if="!categories.length")
        ._head
            ._back(@click="back()" v-if="activeSection >= 0")
                svg-icon(icon-name="arrow-left")._back-icon
                ._back-text {{ locale("back") }}
            ._subtitle(:class="{'without-back-btn': activeSection < 0}") {{ profession ? profession : subtitle }}
        svg(viewBox="-205 -205 410 410" data-tip="sphere")._illustration
            // Главный раздел, первый шаг выбора
            g(v-for="(sectionPath, sectionIndex) in sectionPaths" :key="sectionIndex" v-if="activeSection < 0" @click="activeSection = sectionIndex")._pie-group
                path(:d="sectionPath.d")._pie-section
                svg(width="60px" height="50px" :x="sectionPath.cx" :y="sectionPath.cy")
                    foreignObject(width="100%" height="30")
                        p._text {{ categories[sectionIndex].name }}
                    circle(v-for="(subcategory, index) in categories[sectionIndex].subcategories" r="1.5" :cx="(index === 0) ? 2 : (index + 0.3) * 6" cy="25" :class="{active: subcategory.unlocked}")._subcategory-indicator

            // Категории в выбранном разделе
            g(v-for="(categoryPath, categoryIndex) in categoriesPaths(activeSection)" :key="categoryIndex" v-if="activeSection >= 0 && activeCategory < 0" @click="buyCategory(categoryIndex)" :class="{active: categories[activeSection].subcategories[categoryIndex].unlocked}")._pie-group
                path(:d="categoryPath.d")._pie-section
                svg(width="50px" height="50px" :x="categoryPath.cx" :y="categoryPath.cy")
                    foreignObject(width="100%" height="30")
                        p._text(:class="{large: categories[activeSection].id == 4}") {{ categories[activeSection].subcategories[categoryIndex].name }}
                    circle(v-for="(i, index) in categories[activeSection].subcategories[categoryIndex].itemsTotal" r="1" :cx="(index === 0) ? 2 : (index + 0.5) * 4" cy="25" v-if="categories[activeSection].subcategories[categoryIndex].itemsTotal > 1")._subcategory-indicator

            // Описание внутри выбранной категории
            g(v-for="(subcategoryPath, subcategoryIndex) in subcategoriesPaths(activeCategory)" :key="subcategoryIndex" v-if="activeCategory >= 0" @click="showDescription(subcategoryIndex)")._pie-group.active
                path(:d="subcategoryPath.d")._pie-section
                svg(width="40px" height="50px" :x="subcategoryPath.cx" :y="subcategoryPath.cy")
                    foreignObject(width="100%" height="30")
                        p(:class="subcategoryPath.text.size" :style="{'text-align': subcategoryPath.text.position}")._text {{ categories[activeSection].subcategories[activeCategory].items[subcategoryIndex].name }}
        
        ._merlin-box(:class="{professions: subtitle === 'Профессии' && professionOrientationCompleted}")
            ._merlin-inner
                vue-lottie-player(autoplay controls loop mode="normal" :animationData="analyzeFile")._player
</template>

<script>
import { descriptionMixins } from "../../../mixins/descriptionMixins";
import VueLottiePlayer from "vue-lottie-player";

const CIRCLE_START_POSITION = Math.PI;

export default {
    name: "PersonDescriptionAlternative",
    components: { VueLottiePlayer },
    mixins: [descriptionMixins],
    data: () => ({
        activeSection: -1,
        activeCategory: -1,
        gap: 3,
        outerRadius: 105,
        innerRadius: 5,
        innerDecor: false,
        categoryInfo: {
            name: null,
            price: null,
            category: null,
            subCategory: null,
        },
        analyzeFile: require("../../../assets/lottie-files/analyze.json"),
        professionCategories: [],
        profession: null,
        showBlades: true,
    }),
    computed: {
        personUuid: (state) => state.$route.params.uuid,
        categories: (state) => state.$store.getters["person/personCategories"],
        sectionPaths() {
            const step = (2 * Math.PI) / this.categories.length;
            return this.getPaths(this.categories, step);
        },
        subtitle: (state) => {
            return state.activeCategory >= 0
                ? state.categories[state.activeSection].subcategories[
                    state.activeCategory
                ].name
                : state.activeSection >= 0
                    ? state.categories[state.activeSection].name
                    : state.locale("choose-type");
        },
        avatar: (state) => state.$store.getters["person/photos"]["avatar"],
        windowWidth: (state) => state.$store.getters["windowWidth"],
        planIds: (state) => state.$store.getters["planIds"],
        currentData: function () {
            return this.professionCategories;
        },
    },
    watch: {
        activeSection() {
            this.$emit("changeCategory", this.activeSection);
            this.$store.dispatch("setDescriptionSectionId", this.activeSection);
        },
    },
    async mounted() {
        await this.personGetDescription(this.personUuid);
        setTimeout(() => {
            this.innerDecor = true;
        }, 2000);
    },
    destroyed() {
        this.$store.dispatch("setDescriptionSectionId", -1);
    },
    methods: {
        getPaths(cats, step) {
            return cats.map((_, i) => {
                return this.pie(
                    CIRCLE_START_POSITION + step * i,
                    step,
                    this.outerRadius,
                    this.innerRadius,
                    this.gap,
                );
            });
        },
        categoriesPaths(catId) {
            if (catId >= 0) {
                const category = this.categories[catId];
                const step = (2 * Math.PI) / category.subcategories.length;
                return this.getPaths(category.subcategories, step);
            }
        },
        subcategoriesPaths(catId) {
            if (catId >= 0) {
                const category =
                    this.categories[this.activeSection].subcategories[catId];
                const step = (2 * Math.PI) / category.itemsTotal;
                let fakeArray = [];
                for (let i = 1; i <= category.itemsTotal; i++) {
                    fakeArray.push({});
                }
                return this.getPaths(fakeArray, step);
            }
        },
        pie(from, angle, outerRadius, innerRadius, gap = 0) {
            const or = outerRadius;
            const ir = innerRadius;
            let d;

            if (angle > Math.PI) {
                const x0 = or * Math.cos(from);
                const y0 = or * Math.sin(from);
                d = `M ${x0} ${y0} `;

                const x1 = or * Math.cos(from + Math.PI);
                const y1 = or * Math.sin(from + Math.PI);
                d += `A ${or} ${or} 0 0 1 ${x1} ${y1} `;
                d += `A ${or} ${or} 0 0 1 ${x0} ${y0} `;

                const x2 = ir * Math.cos(from);
                const y2 = ir * Math.sin(from);
                d += `M ${x2} ${y2} `;

                const x4 = ir * Math.cos(from + Math.PI);
                const y4 = ir * Math.sin(from + Math.PI);
                d += `A ${ir} ${ir} 0 0 0 ${x4} ${y4} `;

                d += `A ${ir} ${ir} 0 0 0 ${x2} ${y2} Z`;
            } else {
                const og = Math.asin(gap / 2 / outerRadius);
                const ig = Math.asin(gap / 2 / innerRadius);

                const x0 = or * Math.cos(from + og);
                const y0 = or * Math.sin(from + og);
                d = `M ${x0} ${y0} `;

                const x2 = or * Math.cos(from + angle - og);
                const y2 = or * Math.sin(from + angle - og);
                d += `A ${or} ${or} 0 0 1 ${x2} ${y2} `;

                const x3 = ir * Math.cos(from + angle - ig);
                const y3 = ir * Math.sin(from + angle - ig);
                d += `L ${x3} ${y3} `;

                const x4 = ir * Math.cos(from + ig);
                const y4 = ir * Math.sin(from + ig);
                d += `A ${ir} ${ir} 0 0 0 ${x4} ${y4} `;
                d += `L ${x0} ${y0} Z`;
            }

            const mid = from + angle / 2;

            let different = 0,
                text = {
                    size: "",
                    position: "",
                };
            if (this.activeSection >= 0 && this.activeCategory >= 0) {
                const descCount =
                    this.categories[this.activeSection].subcategories[
                        this.activeCategory
                    ].itemsTotal;
                if (descCount === 2) {
                    different = 15;
                    text.size = "large";
                    text.position = "center";
                }
                if (descCount >= 5) {
                    text.size = "small";
                }
                if (descCount > 6) {
                    different = -10;
                }
            }

            return {
                d,
                text,
                cx:
                    (or + ir) * 0.65 * Math.cos(mid) +
                    angle * ir -
                    35 -
                    different,
                cy:
                    (or + ir) * 0.65 * Math.sin(mid) +
                    angle * ir -
                    30 -
                    different,
            };
        },
        buyCategory(catId) {
            const category =
                this.categories[this.activeSection].subcategories[catId];
            if (category.unlocked) {
                if (category.itemsTotal > 1) {
                    this.activeCategory = catId;
                } else {
                    this.prependShowModal(this.activeSection, catId, 0);
                }
            } else {
                this.$store.dispatch("changeConfirmDialog", {
                    show: true,
                    loading: false,
                    title: this.locale("need-subscribe"),
                    text: this.locale("need-subscribe-text", {
                        category: this.subtitle,
                    }),
                    price: 0,
                    comparisonCount: "",
                    buttons: true,
                    confirmText: this.locale("btn"),
                    type: "personDescriptionSubscribeAttempt",
                });
            }
        },
        showDescription(descId) {
            console.log(descId);
            this.prependShowModal(
                this.activeSection,
                this.activeCategory,
                descId,
            );
        },
        back() {
            if (this.profession) {
                this.profession = null;
                return;
            }
            if (this.activeCategory >= 0) {
                this.activeCategory = -1;
                return;
            }
            if (this.activeSection >= 0) {
                this.activeSection = -1;
            }
        }
    },
};
</script>

<style lang="scss">
@import "person-description";
</style>

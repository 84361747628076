<template lang="pug">
    .content__main
        .analyze
            loading(v-if="loading")._loading
            template(v-else)
                choose-want(:type="decorationType" :outerWaveEnter="true" :outerLineWave="true" :innerFillWave="true" :innerWave="!photoCompareStatus" :sections="true")
                person-description-alternative._description-wrap(@change-decoration-type="changeDecorationType")
</template>

<script>
import PersonDescription from "@/components/person/person-description/person-description";
import ChooseWant from "@/components/auth/choose-want/choose-want";
import gql from "graphql-tag";
import getPersonQuery from "@/graphql/queries/getPerson.query.graphql";
import CompareWithPhoto from "@/components/person/compare-with-photo/compare-with-photo";
import PersonDescriptionAlternative from "../components/person/person-description/person-description-alternative.vue";

export default {
    name: "PersonAnalyze",
    components: {
        PersonDescriptionAlternative,
        CompareWithPhoto,
        ChooseWant,
        PersonDescription,
    },
    data() {
        return {
            loading: true,
            photoCompareStatus: false,
            decorationType: "person",
            person: {},
        };
    },
    computed: {
        personUuid: (state) => state.$route.params.uuid,
    },
    async created() {
        await this.$store.dispatch("person/clearPerson");
        await this.getPerson();
        this.loading = false;
    },
    destroyed() {
        this.$bus.$off("showPhotoCompare").$off("backToPersonDescription");
    },
    methods: {
        changeDecorationType(type) {
            this.decorationType = type;
        },
        async getPerson() {
            await this.$apollo
                .query({
                    query: gql(getPersonQuery),
                    variables: {
                        uuid: this.personUuid,
                    },
                })
                .then(async (r) => {
                    this.person = r.data.getPerson;
                    if(!r.data.getPerson.public_opened) {
                        this.$router.push("/");
                    }
                });
        },
    },
};
</script>

<style lang="scss">
.analyze {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 150px);

    @media (max-width: 991px) {
        height: initial;
    }

    &__loading {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__description-wrap {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @media (max-width: 991px) {
            position: initial;
            height: initial;
        }
    }

    &__compare-with-photo {
        //margin-top: 80px;
    }
}
</style>
